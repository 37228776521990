import { useNav } from "../context"
import { useEffect } from "react"

export function useSpecialPage(boolean) {
  const { isSpecial, makeSpecial } = useNav()

  useEffect(() => {
    makeSpecial(boolean)
  }, [])
}
