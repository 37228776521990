import React, { useCallback, useState, useEffect } from "react"
import { ContentWrapper } from "../styles/Wrapper"
import styled from "styled-components"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import Imprint from "../components/DataPrivacy/Imprint"
import DataPrivacy from "../components/DataPrivacy/DataPrivacy"
import { useSpecialPage } from "../hooks/useSpecialPage.hook"
import { useNav } from "../context"
import Layout from "../components/layout"

const Imprint_and_Privacy = () => {
  const [selected, setSelected] = useState(1)
  const { checkIntersection } = useNav()

  function newSelect(newNum) {
    if (selected === newNum) {
      setSelected(3)
    } else {
      setSelected(newNum)
    }
  }

  const { nonCase } = useNav()
  useEffect(() => {
    nonCase()
  }, [])

  return (
    <Layout>
      <SEO title="Datenschutz & Impressum" />
      <Container>
        <ContentWrapper>
          <div style={{ paddingTop: "var(--navHeight)" }} className="wrapper">
            <DeciderSection selected={selected}>
              <div className="selection" onClick={() => newSelect(1)}>
                <h4 className="title">Impressum</h4>
                <section className="links">⟶</section>
              </div>
              <div className="selection" onClick={() => newSelect(2)}>
                <h4>Data Privacy</h4>
                <section className="links">⟶</section>
              </div>
            </DeciderSection>
            <div className="content">
              {selected === 1 ? <Imprint /> : <DataPrivacy />}
            </div>
            <DeciderSectionPhone selected={selected}>
              <div className="selection" onClick={() => newSelect(1)}>
                <h4 className="title-main">Impressum</h4>
                <section className="links">
                  {selected === 1 ? "↓" : "↑"}
                </section>
              </div>
              {selected === 1 && <Imprint />}
              <div className="selection" onClick={() => newSelect(2)}>
                <h4 className="title-main">Data Privacy</h4>
                <section className="links">
                  {selected === 2 ? "↓" : "↑"}
                </section>
              </div>
              {selected === 2 && <DataPrivacy />}
            </DeciderSectionPhone>
          </div>
        </ContentWrapper>
      </Container>
    </Layout>
  )
}

export default Imprint_and_Privacy

const Container = styled.div`
  min-height: 100vh;
  padding: 80px 0 112px;

  @media (min-width: 1440px) {
    padding: 80px 80px 112px;
  }
  background: var(--backgrounds);

  @media (max-width: 1024px) {
    .content {
      display: none;
    }
  }
  .wrapper {
    @media (min-width: 1024px) {
      display: flex;

      .content {
        width: 70%;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 56px 0 80px;
  }
`
const DeciderSectionPhone = styled.div`
  div {
    padding-bottom: 1.6rem;
    border-bottom: 1px solid var(--beige);
    display: flex;
    align-content: center;
    cursor: pointer;
    color: var(--beige);
    justify-content: space-between;
  }

  .selection:nth-of-type(${props => props.selected}) {
    color: var(--grey);
  }

  .selection + .selection {
    padding-top: 1.6rem;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`
const DeciderSection = styled.div`
  div {
    padding-bottom: 1.6rem;
    border-bottom: 1px solid var(--beige);
    display: flex;
    align-content: center;
    cursor: pointer;
    color: var(--beige);
    justify-content: space-between;
  }

  .selection:nth-of-type(${props => props.selected}) {
    color: var(--grey);
  }

  .selection + .selection {
    padding-top: 1.6rem;
  }

  @media (min-width: 1440px) {
    /* min XL */
    margin-right: 104px;
    width: 30%;
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    /* min L */
    width: 30%;
    margin-right: 24px;
  }

  @media (max-width: 1024px) {
    /* M */

    display: none;
  }
`
