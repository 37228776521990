import React, { useCallback, useState } from "react"
import { ContentWrapper } from "../../styles/Wrapper"
import styled from "styled-components"
import SEO from "../../components/seo"

const Imprint = () => {
  return (
    <ImprintWrapper className="open">
      <h2 className="title">Impressum</h2>
      <div className="grid">
        <h6 className="bold">Firma</h6>
        <div className="flex-col">
          <p className="body">WTW Weidinger · Thiele · Wenninger</p>
          <p className="body">
            Steuerberater Wirtschaftsprüfer <br/>Partnerschaftsgesellschaft mbB
          </p>
          <p className="body">Sendlinger Str. 7</p>
          <p className="body">D-80331 München</p>
          <p className="body">T: + 49-89-21 11 47-0</p>
          <p className="body">F: + 49-89-21 11 47-44</p>
          <p className="body">
            E:{" "}
            <span>
              <a href="mailto:info@wtw-muc.de">info@wtw-muc.de</a>
            </span>
          </p>
        </div>

        <h6 className="bold">UID-Nummer</h6>
        <div className="flex-col">
          <p className="body">DE283556957</p>
        </div>

        <h6 className="bold">Partnerschaftsregister</h6>
        <div className="flex-col">
          <p className="body">Amtsgericht München, PR 1151</p>
        </div>

        <h6 className="bold">Vermögensschaden-Haftpflichtversicherung</h6>
        <div className="flex-col">
          <p className="body">
            HDI Versicherung AG, Buchholzer Str. 98, 30655 Hannover
          </p>
          <p className="body">Vertrags-Nr. 70-009561455-0</p>
        </div>

        <h6 className="bold">Partner</h6>
        <div className="flex-col">
          <p className="body">
            Jörg Weidinger, Steuerberater / Wirtschaftsprüfer
          </p>
          <p className="body">Ulrich Thiele, Steuerberater </p>
          <p className="body">
            Dr. Robert Wenninger, Rechtsanwalt / Steuerberater
          </p>
        </div>
      </div>
      <div className="divider"></div>
      <div className="grid">
        <h6 className="bold">Zuständige Aufsichtsbehörde der Steuerberater</h6>
        <div className="flex-col">
          <p className="body">Steuerberaterkammer München</p>
          <p className="body">Körperschaft des öffentlichen Rechts</p>
          <p className="body">Nederlinger Straße 9</p>
          <p className="body">D-80638 München</p>
          <p className="body">T: + 49-89-15 79 02 0</p>
          <p className="body">F: + 49-89-21 11 47 44</p>
          <p className="body">
            E: {" "}
            <span>
              <a href="mailto:info@stbk-muc.de">info@stbk-muc.de</a>
            </span>
          </p>
        </div>

        <h6 className="bold">
          Zuständige Aufsichtsbehörde der Wirtschaftsprüfer
        </h6>
        <div className="flex-col">
          <p className="body">Wirtschaftsprüferkammer München</p>
          <p className="body">Körperschaft des öffentlichen Rechts</p>
          <p className="body">Rauchstraße 26</p>
          <p className="body">D-10787 Berlin</p>
          <p className="body">T: + 49-30-72 61 61-0</p>
          <p className="body">F: + 49-30-72 61 61-212</p>
          <p className="body">
            E:{" "}
            <span>
              <a href="mailto:kontakt@wpk.de">kontakt@wpk.de</a>
            </span>
          </p>
        </div>

        <h6 className="bold">Berufsrechtliche Regelungen</h6>
        <div className="flex-col">
          <p className="body">Für Steuerberater </p>
          <p className="body">
            Steuerberatungsgesetz (StBerG) Verordnung zur Durchführung der
            Vorschrift über Steuerberater, Steuerbevollmächtigte und
            Steuerberatungsgesellschaften (DVStB) Berufsordnung der
            Steuerberaterkammern Steuerberatervergütungsverordnung (StBVV). Die Regelungen können bei der <span>Bundessteuerberaterkammer</span>{" "}
            eingesehen werden.
          </p>
          <br/>
          <p className="body">Für Wirtschaftsprüfer</p>
          <p className="body">
            Wirtschaftsprüferordnung (WPO) Berufssatzung für Wirtschaftsprüfer
            Satzung für Qualitätskontrolle Siegelverordnung
            Wirtschaftsprüfer-Berufshaftpflicht-Verordnung. Die Regelungen können
            bei der <span>Wirtschaftsprüferkammer</span> eingesehen werden.
          </p>
        </div>
      </div>
    </ImprintWrapper>
  )
}

export default Imprint

const ImprintWrapper = styled.div`
  * {
    color: black;
  }
 

  span {
    color: var(--blue);
  }
  a {
    color: var(--blue);
  }
  .title {
    margin-bottom: 24px;
  }
  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--beige);
    margin: 32px 0;
  }

  .grid {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-gap: 32px 24px;
  }

  @media (max-width: 600px) {
    /* S */

    .grid {
      grid-template-columns: auto;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  //animation
  border: none;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
